export enum Role {
  All = "All",
  Admin = "Admin",
  Doctor = "Doctor",
  Patient = "Patient",
  Administrator = "Admin",
  SuperAdministrator = "Super Administrator",
  Teacher = "Teacher",
  SuperStockist = "Superstockist Admin",
  Stockist = "Stockist Admin",
  Plr = "PLR Admin",
  Student = "Student",
}
